<template>
  <div>
    <div class="card">
      <div class="card">
        <apexchart
          :options="chartOptions"
          :series="series"
          type="bar"
          width="100%"
          height="400"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
import module from "@/core/modules/CrudModule.js";
export default {
  props: {
    statistic: Object,
  },

  components: {
    ApexCharts,
  },

  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "Jumlah",
          data: [],
        },
      ],
      chartOptions: {
        labels: [],
        chart: {
          type: "bar",
          categories: [],
        },
        legend: {
          fontSize: "20px",
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "15px",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "15px",
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: "#38c762",
      },
    };
  },

  methods: {
    async getData(tool) {
      window.arrData = [];
      // let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get("statistic");
      response = response.items.profession;

      this.series[0].data = response.map((response) =>
        parseInt(response.amount)
      );
      window.arrData = response.map((response) => response.name);
      console.log(window.arrData, "window.arrData");
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels: window.arrData,
          xaxis: {
            categories: window.arrData,
          },
        },
      };
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
