<template>
  <div>
    <div class="card">
      <div class="card">
        <apexchart
          :options="chartOptions"
          :series="series"
          type="pie"
          width="100%"
          height="400"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
import module from "@/core/modules/CrudModule.js";
export default {
  props: {
    statistic: Object,
  },

  components: {
    ApexCharts,
  },

  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        labels: [],
        chart: {
          type: 'pie',
           categories: [],
        },
          xaxis: {
          categories: [],
        },
        legend: {
          position: "bottom",
          fontSize: "20px",
        },
        // colors:['#38c762','#f47b20'],
      },
    };
  },

  methods: {
    async getData(tool) {
      window.arrData = [];
      // let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get("statistic");
       response = response.items.prayPlace;

      
      let prayPlace = [];
      let labelprayPlace = [];
    
      //  labelprayPlace
      for (let b = 0; b < response.length; b++) {
          prayPlace.push(response[b].amount)
          labelprayPlace.push(response[b].pray_place_name)
      }

      this.series = prayPlace
      window.arrData = labelprayPlace;
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels : window.arrData,
          xaxis: {
            categories: window.arrData,
            // title: {
            //   text: 'Persentase Pendidikan Penduduk',
            // },
          },
        },
      };
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
